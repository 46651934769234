import React from 'react';

const Notes = ({ notes }) => {
  return (
    <>
      {notes && notes.length > 0 && notes[0] && <div className="notes">
        {notes.map((note = '', index) => <div className="noteline" key={index}>
          {note?.split('\n').map(line => <div key={line}>{line}</div>)
          }
        </div>)}
      </div>}
    </>
  )
}

export default Notes
