import React from 'react';

const Footer = ({ supplier, document }) => {
  return (
    <div className="footer">
      {supplier.registrationname || supplier.name}  -  {supplier.companyid}  -  {supplier.legalform}
      <br />
      <div className="document">
        {document.id && <div>
          <div onClick={() => {
            function base64ToArrayBuffer(_base64Str) {
              var binaryString = window.atob(_base64Str);
              var binaryLen = binaryString.length;
              var bytes = new Uint8Array(binaryLen);
              for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
              }
              return bytes;
            }
            var byte = base64ToArrayBuffer(document.binaryobject);
            var blob = new Blob([byte], { type: 'application/pdf' });
            window.open(URL.createObjectURL(blob), "_blank");

          }} >
            <img src="/img/pdf.svg" className="pdficon" alt="PDF"/>
            <div className="documentname">{document.description || document.id}</div>
          </div>

        </div>}
      </div>
    </div>
  )
}

export default Footer
